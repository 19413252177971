

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/comparer-meilleurs-avocats-droit-immigration-quebec.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost31 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Comparez les meilleurs avocats en immigration au Québec en 2020 - Soumissions Avocat"
        description="Vous souhaitez présenter une demande de résidence permanente, de citoyenneté ou de permis d’étude? Assurez-vous d’être approuvé par les services d’immigration en demandant l’aide d’un avocat!"
        image={LeadImage}>
        <h1>Comparez les meilleurs avocats en immigration au Québec en 2020</h1>

					
					
						<p>Immigrer au Canada et devenir officiellement citoyen de ce beau grand pays est un rêve chéri par plusieurs et avec raison! Avec la qualité de vie offerte sous l’unifolié, qui ne serait pas prêt à endurer ces hivers ardus n’est-ce pas? Avant de pouvoir croquer à pleines dents dans cette vision, il faut toutefois passer par quelques étapes préalables qui posent leur lot de complexité.</p>
<p><StaticImage alt="Comparer les meilleurs avocats en immigration au Québec" src="../images/comparer-meilleurs-avocats-droit-immigration-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Ces étapes sont celles qu’un avocat en droit de l’immigration vous aidera à franchir pour que votre simple statut de visiteur ne se transforme en citoyenneté le plus rapidement possible.</strong> Comme chaque pays possède ses propres règles d’immigration, celles-ci varient beaucoup d’un endroit à l’autre. Voici toutefois les services avec lesquels un avocat en droit de l’immigration pourra vous aider sans problème!</p>
<h2>Quels sont les services offerts par les avocats en droit de l’immigration?</h2>
<p>Dépendamment de l’objectif convoité par votre démarche, il vous faudra passer par différents processus d’immigration pour y parvenir! Il existe des démarches pour tous les types d’immigrants, aussi bien pour ceux qui désirent faire leurs études ici pour repartir que ceux qui veulent élever leur famille au Canada.</p>
<p><strong>Afin de vous guider dans votre démarche, voici les principaux services proposés par les avocats en droit de l’immigration au Canada.</strong></p>
<p><strong>Obtention d’un permis de travail</strong></p>
<p>Tout immigrant en sol Canadien qui souhaite travailler doit effectuer la démarche d’obtention d’un permis de travail, à défaut de quoi la pratique de tout ouvrage rémunéré sera illégal. Il existe deux principaux types de permis de travail délivrés par les services d’immigration canadiens, soit le <strong>permis lié à un employeur</strong> et le <strong>permis ouvert</strong>.</p>
<p>Dans ce premier cas, vous n’aurez l’opportunité de travailler que pour l’employeur mentionné dans votre permis de travail pendant une période déterminée et aux conditions qui y sont mentionnées.</p>
<p>La plupart des demandes de permis de travail liés à un employeur se font par l’entremise de programmes d’embauche de travailleurs étrangers et visent principalement des secteurs d’activité économique en demande au Canada.</p>
<p>Pour ce qui est des permis de travail ouverts, ceux-ci permettent aux détenteurs de travailler pour tout type d’employeur, à l’exception de ceux qui sont explicitement exclus en raison de leur non-conformité aux exigences légales. Notamment, les services à caractère érotiques, même ceux légaux, sont inadmissibles à de tels permis de travail.</p>
<p><strong>Carte de résidence permanente </strong></p>
<p>Obtenir le statut de résident permanent est la voie vers la citoyenneté, mais chaque chose en son temps. Il ne faut pas confondre la résidence permanente avec les étrangers qui résident au Canada pour une période prolongée comme les étudiants et les travailleurs.</p>
<p>Les résidents permanents obtiennent, en vertu de leur statut légal, des droits comme la couverture des soins se santé, la protection de tous les lois et droits applicables aux citoyens (ou presque) ainsi que le droit de s’établir n’importe où au Canada.</p>
<p>Des restrictions pour les résidents permanents au niveau du droit de vote et de l’occupation de postes nécessitant des autorisations dites de haut niveau.</p>
<p>La résidence permanente emporte également le droit de demander la citoyenneté après un certain temps, ce qui octroiera à la personne concernée tous les droits et obligations d’un citoyen canadien.</p>
<p><strong>Aide à l’obtention de la citoyenneté</strong></p>
<p>Pour la plupart des étrangers, la demande de citoyenneté est l’étape tant convoitée, mais elle nécessite plusieurs exigences préalables. La plus importante d’entre elles est celle d’obtenir la résidence permanente.</p>
<p><StaticImage alt="Demande citoyenneté avec un avocat canada et québec" src="../images/aide-avocat-obtenir-citoyennete-residence-permanente-visa-travail.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Vous devez détenir le statut de résident permanent et avoir résidé au Canada pour une période de 3 ans lors des 5 dernières années.  Vous aurez également à réussir un test linguistique ainsi qu’un examen de vos connaissances sur les droits et obligations des Canadiens.</p>
<p>Outre ces exigences, c’est une démarche exhaustive qu’un avocat en droit de l’immigration mettra en œuvre pour vous aider à obtenir votre citoyenneté après autant d’années d’attente.</p>
<p><strong>Parrainage</strong></p>
<p>Le parrainage est en lié à la résidence permanente en ce sens qu’il consiste à faire venir un conjoint ou des enfants à charge dans le but de leur octroyer la résidence permanente canadienne. Cela est surtout conditionnel à la capacité du demandeur de subvenir aux besoins des personnes visées par la demande.</p>
<p>Les autres conditions sont celles d’être soi-même un citoyen canadien ou un résident permanent. Autrement, chaque province possède ses propres conditions de parrainage afin de déterminer l’éligibilité des demandeurs.</p>
<p><strong>Demande ou modification de permis d’étude</strong></p>
<p>Il est également possible de faire appel aux services d’un avocat pour présenter une demande de permis d’étude ou pour faire modifier le permis déjà en vigueur. De tels permis sont conditionnels à l’inscription dans un établissement scolaire désigné par les services de l’immigration ainsi qu’à la preuve des moyens financiers nécessaires pour poursuivre de telles ambitions académiques.</p>
<p>La voie la plus courante pour venir étudier au Québec est d’effectuer une demande de Certificat d’acceptation du Québec (ou CAQ). Demandez l’aide d’un avocat pour en obtenir un rapidement!</p>
<p>En temps normal, le permis d’étude cesse d’être valide 90 jours après la fin du programme d’étude. Comme ce permis n’est pas un visa ni un statut de résident permanent, il vous faudra effectuer d’autres démarches pour demeurer au Canada si tel est votre désir.</p>
<p><strong>Demandes d’asile et de réfugié:</strong></p>
<p>Certaines personnes présentes au Canada peuvent faire une demande afin d’être considérées comme réfugié. Ce sont seulement les personnes qui craignent la violence, des répercussions graves ou encore des traitements cruels et inusités dans leur propre pays qui peuvent présenter une telle demande.</p>
<p>De plus, le gouvernement du Canada offre des services d’aide juridique aux demandeurs d’asile afin de favoriser leur accès à la justice.</p>
<p><strong>Quelle que soit votre demande en droit de l’immigration, elle vous demandera de naviguer l’appareil complexe qu’est le gouvernement fédéral. Cela implique des délais, de l’ambiguïté et de la frustration, sans parler du risque de refus. Mettez toutes les chances de votre côté avec un avocat en droit de l’immigration.</strong></p>
<h2>Vous rencontrez des problèmes avec les services d’immigration du Canada? Demandez l’aide d’un avocat!</h2>
<p>Dans l’attribution des permis, demandes et privilèges, les services d’immigration ont une grande latitude et un large pouvoir discrétionnaire. Il se peut donc que la moindre anomalie fasse tomber votre demande autrement impeccable. Voici donc les refus auxquels vous pourriez faire face!</p>
<p><strong>Refus de citoyenneté/résidence permanente :</strong> Les conditions pour obtenir l’un ou l’autre de ces statuts sont précises et pointues. Comme ils confèrent des droits et privilèges importants, plusieurs se font refuser leur demande chaque année.</p>
<p><strong>Quel est dont l’intérêt d’engager un avocat? </strong>Celui-ci connait sur le bout des doigts la façon d’obtenir ces statuts et vous aidera à préparer une demande en bonne et due forme. Ce que vous investirez en frais légaux, vous le sauverez en frais administratifs liés à des refus répétés.</p>
<p><strong>Demande de réfugié :</strong> Tel que mentionné ci-haut, le succès des demandes de réfugié dépend beaucoup du niveau de danger anticipé dans le pays de retour. Si vous croyez que le danger est plus important que ce que les services de l’immigration n’ont pris en compte, un avocat pourra tenter de les convaincre du contraire!</p>
<p><strong>Expulsion du pays :</strong> La principale raison pour expulser un individu du pays est la perpétration d’un crime. Vous n’avez toutefois pas à faire vos valises du jour au lendemain. Des recours existent pour annuler ou retarder une telle décision.</p>
<p><strong>Refus de parrainage :</strong> Il se peut également que votre demande de parrainage ne soit pas acceptée par les autorités compétentes en raison de vos moyens financiers insuffisants ou de votre manque de statut de résident permanent. Il existe cependant des moyens d’expédier les choses en empruntant la voie légale.</p>
<p><strong>
Le fait que vous n’ayez pas encore acquis le statut de citoyen canadien ou de résident permanent ne change rien au fait que vous avez des droits et que la Charte des droits et libertés s’applique à vous. </strong></p>
<p><strong>Il est donc de votre responsabilité de faire valoir vos droits avec un avocat en cas de refus de la part du gouvernement de donner suite à vos demandes.</strong></p>
<h2>Dossier problématique : un avocat peut-il vous aider à immigrer avec succès?</h2>
<p>Juste parce que votre dossier présente quelques complexités, ne signifie pas que vous devriez vous satisfaire d’un refus. Il est vrai que les services d’immigration peuvent être exigeants à bien des égards, mais ces bâtons dans les roues sont rarement incontournables.</p>
<p><StaticImage alt="Comment un avocat peut-il aider avec une demande d’immigration" src="../images/comment-immigrer-canada-avec-succes-recours-avocat-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>On peut notamment penser aux problèmes de santé des arrivants potentiels! </strong>Si vous avez été diagnostiqué d’une maladie contagieuse, il est fort possible que votre demande soit rejetée. La même chose s’avère vraie pour une maladie grave qui mettrait ce qu’on appelle un « fardeau excessif » sur le système de santé canadien.</p>
<p><strong>Les nouveaux arrivants potentiels au passé criminel </strong>se font également refuser l’entrée au Canada, mais ce ne sont pas tous les passés qui sont traités sur un pied d’égalité. Les auteurs de crimes violents ou contre l’humanité ne sont évidemment pas considérés, mais ceux qui n’ont qu’une tâche à leur parcours attribuable à des circonstances fortuites conservent une certaine éligibilité.</p>
<p><strong>Les refus basés sur d’autres motifs que ceux mentionnés</strong> sont aussi considérés comme problématiques et justifient l’intervention d’un avocat. Si vous risquez de retourner dans des circonstances défavorables, voire même dangereuses dans votre pays, l’aide d’un avocat en droit de l’immigration devient alors indispensable!</p>
<p><strong>Si la situation dans votre pays est à un seuil de danger inacceptable, vous pouvez demander à un avocat de vous aider à présenter une demande d’asile ou de réfugié. Ces demandes sont même couvertes par l’aide juridique gouvernementale si votre situation financière est précaire.</strong></p>
<h2>Vous n’êtes pas encore citoyen canadien et on vous accuse d’un crime?</h2>
<p>Si vous êtes dans une telle situation, le temps pour agir vous est compté. En effet, les lois encadrant les résidents permanents auteurs de crimes diffèrent des citoyens canadiens en ce sens que vous risquez la déportation en cas de culpabilité. Vous bénéficiez évidemment toujours de la présomption d’innocence en vertu de la loi canadienne, mais votre culpabilité pourrait vous faire perdre votre statut.</p>
<p>Le risque de déportation est applicable lorsque vous avez commis un crime grave, qu’un tribunal pénal vous a condamné et que l’Agence des services frontaliers du Canada prend les mesures pour vous expulser. <strong>Qu’est-ce qu’un crime grave au sens d’une procédure d’expulsion?</strong></p>
<ul>
<li><strong>Crime passible d’une peine de plus de 10 ans d’emprisonnement</strong></li>
<li><strong>Conduite avec les facultés affaiblies / alcool au volant</strong></li>
<li><strong>Vol ou possession de biens volés</strong></li>
<li><strong>Crimes violents</strong></li>
<li><strong>Utilisation de faux documents officiels (passeports, permis, cartes de crédit, etc.)</strong></li>
</ul>
<p><strong>Pourquoi une expertise en immigration est-elle indispensable dans un tel scénario?</strong> Parce qu’engager un criminaliste n’ayant aucune connaissance en droit de l’immigration pourrait vous nuire, car des conseils forts judicieux pour un citoyen pourraient être néfastes pour un résident permanent qui risque l’expulsion.</p>
<h2>Questions fréquentes pour les avocats d’immigration au Québec</h2>
<p>Vous tentez tant bien que mal de vous retrouver parmi le chaos qu’est le processus d’immigration canadien. Vous entendez des faussetés à gauche et à droite sur l’immigration au Canada. Heureusement, nous avons quelques réponses pour vous aux questions les plus fréquentes!</p>
<p><strong>Est-ce que les services d’immigration sont admissibles à l’aide juridique?</strong></p>
<p>L’aide juridique n’est pas applicable à tous les types de demandes en droit de l’immigration. Ce sont principalement les services à vocation humanitaire qui seront admissibles à l’aide juridique, comme les demandes de réfugiés, d’asile, de révision de détention ainsi que les dossiers de perte de statuts (résidence permanente et réfugié).</p>
<p><strong>
</strong><StaticImage alt="Services immigration admissibles aide juridique" src="../images/questions-frequentes-immigration-quebec-avocat-expulsion-refugie.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Un avocat peut-il m’aider à immigrer dans un autre pays (Ex : aux Etats-Unis)?</strong></p>
<p>Tout à fait! Si vous désirez vous envoler vers le pays de l’Oncle Sam pour refaire votre vie sous le chaud soleil de la Floride, un avocat en droit de l’immigration peut certainement vous aider à obtenir la fameuse « green card » tant convoitée.</p>
<p><strong>Comment fonctionnent les honoraires d’un avocat en droit de l’immigration?
</strong>
Mis à part les honoraires d’un dossier d’aide juridique, les avocats en droit de l’immigration chargent soit des taux horaires qui feront varier le coût de la facture selon le nombre d’heures investies ou encore un montant forfaitaire.</p>
<p>Le montant forfaitaire est surtout applicable pour les demandes procédurales telles que l’obtention d’un permis de travail étant donné que l’avocat en question a l’habitude de ce type de dossiers et qu’ils présentent peu de surprises habituellement!</p>
<p><strong>Faut-il avoir un statut particulier pour retenir les services d’un avocat?</strong></p>
<p>Pas du tout! Le droit d’être représenté par un avocat s’applique même si vous n’êtes pas encore un citoyen canadien. Il est donc tout à fait possible, voire même souhaitable que vous reteniez les services d’un avocat en étant toujours un résident permanent ou encore un étudiant convoitant la citoyenneté.</p>
<h2>Trouvez les meilleurs avocats en droit de l’immigration avec Soumissions Avocat!</h2>
<p>Votre vision d’un avenir au Canada est plus claire que jamais? Vous souhaitez prendre tous les moyens nécessaires pour garantir un avenir viable pour vous et votre famille en sol canadien? Vous êtes venu au bon endroit pour obtenir de l’aide dans cette démarche!</p>
<p>Les avocats en droit de l’immigration sont parmi les professionnels les plus polyvalents et versatiles au Québec. Ils sont en mesure d’entreprendre une variété de démarches légales et de vous obtenir gain de cause rapidement en cas de problème.</p>
<p><strong>Par où commencer cette démarche avec un tel avocat? Vous laissez la chance à Soumissions Avocat de vous aider à trouver l’expert qu’il vous faut!</strong></p>
<p><strong>Nous faisons affaire avec les meilleurs avocats en droit de l’immigration au Québec et nous serons en mesure d’assurer la mise en contact! Tout ce qu’il vous reste à faire, c’est de remplir gratuitement le formulaire, alors à quoi bon hésiter?</strong></p>
    </SeoPage>
)
export default BlogPost31
  